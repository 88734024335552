export default {
  methods: {
    fetchPricelist(path) {
      this.$store
        .dispatch(`${path}/fetchWizard`)
        .then(() => (this.pricelistsAvailable = true))
        .catch(e => {
          e.type === 'no_available_pricelist'
            ? (this.pricelistsAvailable = false)
            : (this.pricelistsAvailable = true);
        });
    },
  },
};
