<template>
  <div class="partners">
    <div class="partners-info">
      <div class="partners-info__content">
        <page-title class="partners__title page-title">
          {{ $t('title') }}
        </page-title>
        <main-card class="partners__card">
          <div class="partners__desc standart-text">
            {{ $t('text_0') }}
            <br />
            {{ $t('text_1') }}
          </div>
          <!--          <br />-->
          <!--          <div v-for="(item, key) in partners" :key="key" class="l-col">-->
          <!--            <div v-if="item.type !== 'select'">-->
          <!--              <base-input-->
          <!--                :type="item.type"-->
          <!--                :value="item.value"-->
          <!--                :name="item.name"-->
          <!--                :label="item.label"-->
          <!--                class="partners__field"-->
          <!--                :readonly="true"-->
          <!--                :info="true"-->
          <!--              >-->
          <!--              </base-input>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <br />-->
          <!--          <div v-if="referals && provider === '3'" class="partners__links">-->
          <!--          <div v-if="referals" class="partners__links">-->
          <!--            <base-button color="error" target="_blank" @click="linkTo">-->
          <!--              {{ $t('partners') }}-->
          <!--            </base-button>-->
          <!--          </div>-->
        </main-card>
      </div>
    </div>
  </div>
</template>

<script>
import MainCard from '@/components/MainCard/MainCard.vue';
import providerChange from '@/mixins/providerChange';
import { IS_PROD, urlToProject } from '@/layouts/LoginPage/mixins/currentProvider';

export default {
  name: 'PartnersMain',
  components: { MainCard },
  // CardBlock, MainCard, BaseInput
  mixins: [providerChange],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // resellerTrue: false,
    };
  },
  computed: {
    isReseller() {
      return !!(
        this.$store.state.moduleProfile.partners_group &&
        this.$store.state.moduleProfile.partners_group.affiliate &&
        this.partnersGroup.find(x => x.v.includes('Партнерская программа'))
      );
    },
    partnersGroup() {
      return this.$store.state.moduleProfile.partners_group.affiliate || 'null';
    },
    isLoading() {
      return this.loading || !this.isReady;
    },
    profile() {
      return this.$store.state.moduleProfile.profile;
    },
    partners() {
      return this.$store.getters['moduleProfile/partners'];
    },
    referals() {
      if (this.$store.getters['moduleProfile/referals']) {
        return this.$store.getters['moduleProfile/referals'];
      } else return null;
    },
    isReady() {
      return this.profile && this.partners;
    },
    provider() {
      return IS_PROD
        ? urlToProject[window.location.origin]
        : this.$store.getters['moduleProviders/provider'];
      // return this.$store.getters['moduleProviders/current'];
    },
  },
  watch: {
    provider() {
      return this.$store.getters['moduleProviders/current'];
    },
  },
  mounted() {
    this.$store.dispatch('moduleProfile/setPartnersLink').catch(() => {});
    this.$store.dispatch('moduleProfile/getReferalsLink').catch(() => {});
  },
  methods: {
    linkTo() {
      window.open(this.referals.v);
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "partners": "Партнерская программа",
      "title": "Партнёрам",
      "desc": "В данном разделе вы можете ознакомиться с обновлениями политики конфиденциальности, а также посмотреть список действий",
      "ref": "Что такое Реферальная программа? Все очень просто: Вы рекомендуете клиенту наши услуги или помогаете ему, к примеру, зарегистрировать сервер в Русоникс. После этого, вы начинаете получать вознаграждение в течение всего времени, пока клиент пользуется нашими услугами.",
      "text_0": "Уважаемый партнер!",
      "text_1": "Чтобы получить доступ в партнерский портал, пожалуйста, свяжитесь с менеджером по работе с партнерами Astra Cloud.",
      "text_2": "Используя нижеприведённые ссылки для привлечения новых клиентов к Вашей партнёрской программе, Вы сможете получать ежемесячно бонусные баллы и оплачивать ими услуги хостинга! ",
      "text_3": "Для этого достаточно, чтобы новый клиент перешёл по ссылке, зарегистрировался в системе биллинга, выбрал нужный тариф и оплатил его.",
      "text_4": "* Начисление вознаграждения осуществляется в начале месяца, следующего за отчётным."

    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.partners {

  &__card {
    margin-top: 2rem;

  +breakpoint(md-and-up) {
    max-width: 40rem;
  }
  }
  &-info {
  +breakpoint(sm-and-up) {
  flexy(space-betweeb, center);
  }

    &__content {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;

    +breakpoint(sm-and-up) {
      justify-content: flex-start;
      margin-right: 1.5rem;
      flex: 1 1 auto;
    }
    }

    &__loader {
    flexy(center, center);
      flex: 1 1 auto;
    }

    &__create {
      flex: 0 0 auto;
      margin-top: 1.5rem;
      max-width: 160px;
      visibility: hidden;

      &.visible {
        visibility: visible;
      }

    +breakpoint(sm-and-up) {
      margin: 0 0 0 auto;
    }
    }
  }

}
</style>
