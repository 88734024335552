var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-table-row',_vm._b({staticClass:"tariffs-table-item",attrs:{"columns":_vm.columns,"col-class":"tariffs-table-item__col"},scopedSlots:_vm._u([{key:"check",fn:function(){return [_c('base-checkbox',{attrs:{"value":_vm.item.id,"model-value":_vm.checked},on:{"change":function($event){return _vm.$emit('checked', $event)}}})]},proxy:true},{key:"server",fn:function(){return [_c('router-link',{staticClass:"tariffs-table-item__link medium-text",attrs:{"to":{ name: 'zmailDetail', params: { id: _vm.item.id } }}},[_vm._v(" "+_vm._s(_vm.item.title)+" ")])]},proxy:true},{key:"mailname",fn:function(){return [_c('div',{staticClass:"tariffs-table-item__ip note-color"},[_vm._v(" "+_vm._s(_vm.item.mailname)+" ")])]},proxy:true},{key:"id",fn:function(){return [_c('div',{staticClass:"tariffs-table-item__ip note-color"},[_vm._v(" "+_vm._s(_vm.item.id)+" ")])]},proxy:true},{key:"expiredate",fn:function(){return [_c('div',{staticClass:"tariffs-table-item__ip-promise"},[(_vm.item.promise !== 'null')?_c('plain-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.promiseOnItem,
          autoHide: false,
          placement: 'top-start',
          container: false,
        }),expression:"{\n          content: promiseOnItem,\n          autoHide: false,\n          placement: 'top-start',\n          container: false,\n        }"}],staticClass:"allowed-user-rights__promise",attrs:{"icon":"promise-icon"}}):_vm._e(),_c('div',{staticClass:"tariffs-table-item__ip note-color"},[_vm._v(" "+_vm._s(_vm.$d(_vm.item.expiredate, 'short'))+" ")])],1),(_vm.hasProlongTool)?_c('plain-button',{attrs:{"color":"primary"},on:{"click":function($event){_vm.costAutoProlong === '0.00' ? _vm.singleProlongFree(_vm.item) : _vm.singleProlong(_vm.item)}}},[_vm._v(" "+_vm._s(_vm.$t('actions.prolong'))+" ")]):_vm._e()]},proxy:true},{key:"autoprolong",fn:function(){return [_c('div',{staticClass:"tariffs-table-item__icon"},[_c('zmail-autoprolong',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.isAutoProlong,
          autoHide: false,
          placement: 'left-start',
        }),expression:"{\n          content: isAutoProlong,\n          autoHide: false,\n          placement: 'left-start',\n        }"}],attrs:{"tariff":_vm.item}})],1),_c('div',{staticClass:"tariffs-table-item__icon"},[_vm._v(" "+_vm._s(_vm.autoprolongLabel)+" ")])]},proxy:true},{key:"cost",fn:function(){return [_c('div',{staticClass:"tariffs-table-item__cost note-color"},[_vm._v(" "+_vm._s(_vm.$n(_vm.costAutoProlong, 'currency', _vm.$i18n.locale))+" ")])]},proxy:true},{key:"status",fn:function(){return [_c('main-label',{staticClass:"tariffs-table-item__status",attrs:{"color":_vm.item.status.color,"theme":"plain"}},[_vm._v(" "+_vm._s(_vm.$t(`status.${_vm.item.status.key}`))+" ")])]},proxy:true},{key:"more",fn:function(){return [_c('v-popover',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closePopoverOnOutside),expression:"closePopoverOnOutside"}],attrs:{"placement":"left","open":_vm.isPopoverOpen,"trigger":"manual","popover-class":"tariff-table-item__popover"}},[_c('plain-button',{staticClass:"tariffs-table-item__more",attrs:{"icon":"more"},on:{"click":_vm.togglePopoverState}}),_c('zmail-context-menu',{staticClass:"tariff-table-item__context-menu",attrs:{"slot":"popover","tariff":_vm.item},slot:"popover"})],1)]},proxy:true}])},'base-table-row',_vm.item,false))
}
var staticRenderFns = []

export { render, staticRenderFns }